import { WorkBook } from "xlsx";
import { TsDataType } from "../generated";

export type Row = string[];

export enum EnumSheetNames {
  Description = "Description",
  Edge = "Edge",
  Datasource = "Datasource",
  Model = "Model",
  Part = "Part",
  Hierarchy = "Hierarchy",
  Exclusion = "Exclusion",
}

export interface DescriptionData {
  tenant_identifier: string;
  tenant_label: string;
  dashboard_label: string;
  tenant_resolution: string;
}

export interface EdgeData {
  Identifier: string;
  "Heartbeat Interval": number;
  "Active Endpoint": string;
  "Active Edgedevice": string;
}

export interface DatasourceData {
  Identifier: string;
  Label: string;
  Type: string;
  "OPC UA Name": string;
  "OPC UA Node ID": string;
  "Edge Endpoint": string;
  "Time Horizon Duration": string;
  DataType: TsDataType;
  "Datasource Description": string;
  "Typical Value": string;
  Unit: string;
  Scale: number;
  Offset: number;
  "Linked Datasource": string;
}

export interface ModelData {
  Identifier: string;
  Label: string;
  Feature: string;
  "Condition 1": string;
  "Condition 2": string;
  "Condition 3": string;
  "Condition 4": string;
  "Condition 5": string;
  "Condition 6": string;
  "Condition 7": string;
  "Condition 8": string;
  "Condition 9": string;
  "Condition 10": string;
}

export interface PartData {
  Identifier: string;
  Label: string;
}

export interface HierarchyData {
  "Level 1": string;
  "Level 2": string;
  "Level 3": string;
  "Level 4": string;
  "Level 5": string;
  "Level 6": string;
  "Level 7": string;
  "Level 8": string;
  "Level 9": string;
  "Level 10": string;
}

export interface ExclusionData {
  Identifier: string;
  Start: string;
  End: string;
}

export interface SheetsData {
  Description?: DescriptionData;
  Edge?: EdgeData[];
  Parameter?: DatasourceData[];
  Model?: ModelData[];
  Part?: PartData[];
  Hierarchy?: HierarchyData[];
  Exclusion?: ExclusionData[];
}

export interface ExcelState {
  id: string | null;
  pickedFile: any;
  path: string | null;
  name: string | null;
  workbook: WorkBook | null;
  sheetsData: SheetsData;
  isValid: boolean;
  validationMessages: string[];
  setId: (id: string) => void;
  setPickedFile: (pickedFile: any) => void;
  setPath: (path: string) => void;
  setName: (name: string) => void;
  setMetadata: (data: Partial<ExcelState>) => void;
  setWorkbook: (workbook: WorkBook) => void;
}
