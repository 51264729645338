/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from "./aiomatic_warehouse__models__entity__tenant__Tenant__Reference";
import type { TsDataType } from "./TsDataType";
import type { TsSourceType } from "./TsSourceType";

export type TimeSeries = {
  basetype?: TimeSeries.basetype;
  id: string;
  identifier: string;
  tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
  datatype?: TsDataType;
  std?: number;
  mean?: number;
  std_diff?: number;
  mean_diff?: number;
  min?: number;
  max?: number;
  count?: number;
  first_timestamp?: string;
  last_timestamp?: string;
  source_type?: TsSourceType;
  time_horizon_duration?: number;
};

export namespace TimeSeries {
  export enum basetype {
    TIME_SERIES = "TimeSeries",
  }
}
